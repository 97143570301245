.single-lsp {
    position: relative;
    overflow-x: hidden;
}

.container {
    display: grid;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(lg) {
        grid-template-columns: 3fr 1fr;
    }
}

.navbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacer * 0.5;
}

.gnav__ul {
    display: flex;
    margin-bottom: 0;
    list-style-type: none;
}

.nav-item {
    position: relative;
    margin-left: .5rem;
    font-size: $font-size-base * 0.875;
    transition: all .5s;

    a {
        display: inline-block;
        padding: .25rem 1.25rem;
        color: #fff;
    }

    &:hover {
        border-radius: $card-border-radius;
        background-color: #fff;

        a {
            color: $dark;
        }
    }
}

.main {
    overflow-x: hidden;
}

.tmb {
    padding: $spacer $spacer $spacer * 4;
}

.sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.footer {
    padding: 1.5rem 0;

    @include media-breakpoint-down(md) {
        padding-bottom: 4.5rem;
    }
}

.copy {
    font-size: $small-font-size * .9;
    text-align: center;

    a {
        text-decoration: none;
    }
}

.sec {
    padding: $spacer * 4 $spacer;

    &-inner {
        margin: 0 auto;
        min-width: 280px;
        max-width: 790px;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "A"
        "B"
        "C"
        "D"
        "E"
        "F";
    gap: 1rem;
    place-content: center;

    &-item-1 {
        grid-area: A;
    }

    &-item-2 {
        grid-area: B;
    }

    &-item-3 {
        grid-area: C;
    }

    &-item-4 {
        grid-area: D;
    }

    &-item-5 {
        grid-area: E;
    }

    &-item-6 {
        grid-area: F;
    }

    &-odd,
    &-even {
        @include media-breakpoint-up(xl) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
        }

        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(12, 1fr);
        }
    }

    &-odd {
        @include media-breakpoint-up(xl) {
            grid-template-areas:
                "A B"
                "D C"
                "E F";
        }

        @include media-breakpoint-up(xxl) {
            grid-template-areas:
                "A A A A A B B B B B B B"
                "D D D D D D D C C C C C"
                "E E E E E F F F F F F F";
        }
    }

    &-even {
        @include media-breakpoint-up(xl) {
            grid-template-areas:
                "B A"
                "C D"
                "F E";
        }

        @include media-breakpoint-up(xxl) {
            grid-template-areas:
                "B B B B B B B A A A A A"
                "C C C C C D D D D D D D"
                "F F F F F F F E E E E E";
        }
    }

    &-2 {
        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: "A B";
        }
    }

    &-3 {
        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: "A B C"
                "D E F";
        }
    }
}
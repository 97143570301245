// 色
$primary: #0A82C6;
$secondary: #6C757D;
$danger: #E30000;
$warning: #FFCF00;
$light: #F8F8F8;
$dark: #331904;

// ボーダー
$border-radius-lg: 1.25rem;

// カード
$card-bg: $light;

// アコーディオン
$accordion-border-radius: $border-radius-lg;
$accordion-button-active-bg: tint-color($dark, 90%);
$accordion-button-active-color: shade-color($dark, 10%);
$accordion-button-focus-border-color: tint-color($dark, 50%);
$accordion-button-focus-box-shadow: 0 0 0 0.25rem rgba($dark, 25%);
// テーマフォルダパス
$imgDir: "/whp/wp-content/themes/archetype-azu/images/";

// 背景色
@mixin bg-color($colorStart, $colorEnd, $alpha, $imgUrl: null) {
  background: rgba($colorStart, $alpha);
  background-image: linear-gradient(127deg,
      rgba($colorStart, $alpha) 0%,
      rgba($colorEnd, $alpha) 100%),
    $imgUrl;
}

@mixin ao($alpha, $imgUrl: null) {
  @include bg-color($primary, #1eb9ee, $alpha, $imgUrl);
}

@mixin aka($alpha, $imgUrl: null) {
  @include bg-color($danger, $red-500, $alpha, $imgUrl);
}

@mixin ki($alpha, $imgUrl: null) {
  @include bg-color($warning, $yellow-500, $alpha, $imgUrl);
}

@mixin fuji($alpha, $imgUrl: null) {
  @include bg-color($indigo-400, $indigo-300, $alpha, $imgUrl);
}

@mixin midori($alpha, $imgUrl: null) {
  @include bg-color($green-500, $green-400, $alpha, $imgUrl);
}

@mixin daidai($alpha, $imgUrl: null) {
  @include bg-color($orange-500, $orange-400, $alpha, $imgUrl);
}

// 背景属性
@mixin bg-property($bgPos) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: $bgPos;
}

// チェックマーク
@mixin check-mark($content, $bgImg, $textColor, $fontSize) {
  content: $content;
  position: relative;
  top: 6px;
  margin-right: 0.5rem;
  background: $bgImg;
  color: $textColor;
  font-size: $fontSize;
  font-family: "Material Icons Round";
  font-weight: 600;
  line-height: 1.5;
}

// 囲み文字円
@mixin circled($bgColor, $textColor) {
  margin-right: 0.5rem;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: $bgColor;
  color: $textColor;
  text-align: center;
  line-height: 30px;
}

// 装飾 ---------------------------
// 見出し
.h1 {
  margin-bottom: 0;
  padding: 1rem 1rem 0;
}

.h2 {
  @include font-sizes(1.75rem, 2.75rem, 20rem, 87.5rem);
  font-weight: 700;
  text-align: center;
}

.h3 {
  @include font-sizes(1.375rem, 1.75rem, 20rem, 87.5rem);
  font-weight: 700;
  margin-top: 2rem;
}

.h4 {
  @include font-sizes(1.25rem, 1.5rem, 20rem, 87.5rem);
}

.h5 {
  @include font-sizes(1.125rem, 1.25rem, 20rem, 87.5rem);
}

.h6 {
  font-size: 1rem;
}

.subtitle {
  padding: 0 0 0 1.25rem;
  @include font-sizes(1.375rem, 1.75rem, 20rem, 87.5rem);
  font-weight: 600;
  text-transform: uppercase;
}

// 文字色
.text {
  &-ao {
    @include ao(1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-aka {
    @include aka(1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-ki {
    @include ki(1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-fuji {
    @include fuji(1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-midori {
    @include midori(1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-daidai {
    @include daidai(1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-primary {
    color: $primary
  }

  &-danger {
    color: $danger;
  }

  &-light {
    color: $light;
  }

  &-white {
    color: #fff
  }

  &-muted {
    --bs-text-opacity: 1;
    color: rgba($black, .57) !important;
  }
}

// リンクテキスト
.link {
  &-light {
    color: $light;
  }

  &-dark {
    color: $dark;
  }
}

// 背景色
.bg {
  &-ao {
    @include ao(1);
  }

  &-aka {
    @include aka(1);
  }

  &-ki {
    @include ki(1);
  }

  // &-fuji {
  //   @include fuji(1);
  // }

  &-midori {
    @include midori(1);
  }

  // &-daidai {
  //   @include daidai(1);
  // }

  &-white {
    background: #fff;
  }

  &-light {
    background: $light;
  }
}

// ブレットポイント
.bullet {
  margin-bottom: 1.5rem;
  list-style-type: none;
  line-height: 1.5;

  li {
    margin-left: 0.75rem;
  }

  // 丸囲みチェック
  &-check {
    text-indent: -2.5rem;

    li::before {
      @include check-mark("check_circle", none, $primary, $font-size-base * 2);
    }
  }

  &-thumbup {
    text-indent: -2.5rem;

    li::before {
      @include check-mark("thumb_up", none, $warning, $font-size-base * 2);
    }
  }

  &-thumbdown {
    text-indent: -2.5rem;

    li::before {
      @include check-mark("thumb_down", none, $warning, $font-size-base * 2);
    }
  }

  // レゴブロック
  &-lego {
    text-indent: -1.8rem;

    li::before {
      @include check-mark("",
        url(../images/lego.svg) no-repeat center center,
        $secondary,
        $font-size-base * 1.5);
      background-size: contain;
      padding: 10px;
    }
  }
}

// 囲み文字
.circled {
  &-dark {
    @include circled($dark, #fff);
  }

  // &-secondary {
  //   @include circled($accordion-button-active-bg, $dark);
  // }

  // &-white {
  //   @include circled(#fff, $primary);
  // }
}

// 通し番号
.serial-no {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto 1.5rem;
  padding: 0.5rem 0.5rem 0.9rem 0.5rem;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  border-radius: $border-radius-lg;
  color: rgba(#fff, 0.68);
  font-size: $font-size-base;
  font-weight: 600;
  line-height: 1.2;
}

// Bootstrap ---------------------------
// カード
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
  @include box-shadow($card-box-shadow);

  &-header,
  &-footer {
    padding: $card-cap-padding-y * 1.5 $card-cap-padding-x * 1.5;
  }

  &-body {
    padding: $card-spacer-y * 1.5 $card-spacer-x * 1.875;

    @include media-breakpoint-up(sm) {
      padding: $card-spacer-y * 1.875 $card-spacer-x * 1.875;
    }

    @include media-breakpoint-up(lg) {
      padding: $card-spacer-y * 1.875 $card-spacer-x * 2;
    }

    @include media-breakpoint-up(xl) {
      padding: $card-spacer-y * 3 $card-spacer-x * 3;
    }
  }

  &-footer {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
  }
}

// ボタン
.btn {
  &-primary {
    background-image: linear-gradient(160deg,
        hsl(202deg 90% 41%) 0%,
        hsl(200deg 95% 41%) 22%,
        hsl(199deg 99% 41%) 33%,
        hsl(198deg 100% 42%) 42%,
        hsl(197deg 98% 43%) 50%,
        hsl(197deg 94% 45%) 58%,
        hsl(196deg 88% 48%) 67%,
        hsl(196deg 83% 50%) 78%,
        hsl(195deg 86% 53%) 100%);
    background-size: 202% 100%;
    transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      color 0.5s ease 0s, background-color 0.5s ease;
    color: #fff;

    &:hover {
      background-color: rgba($primary, 0.25);
      background-position: -99% 100%;
    }
  }

  &-secondary {
    background-size: 202% 100%;
    background-image: linear-gradient(to right,
        rgba($secondary, .5) 50%,
        rgba($secondary, 1) 50%);
    transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
      color 0.5s ease 0s, background-color 0.5s ease;

    &:hover {
      background-color: rgba($secondary, 0.25);
      background-position: -99% 100%;
    }
  }
}

// 引用
.blockquote-footer {
  margin-top: -1rem;
  font-size: $small-font-size;
  text-align: end;

  &::before {
    content: "—";
  }
}


// ファーストビュー #Lead ---------------------------
.brand {
  padding: 0 .25rem;

  &__img {
    padding: 0.25rem;
    background: #fff;
    border-radius: 0.25rem;
  }

  &__name {
    margin: 0;
    padding-left: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
  }
}

.lead {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;


  &__headline {
    flex-grow: 3;
    max-width: 300vw;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding-top: 3.5rem;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 4rem;
      padding-bottom: 3rem;
    }
  }

  &__prehead {
    @include font-sizes(.6rem, 1rem, 20rem, 87.5rem);
    font-weight: 600;
  }

  &__h1 {
    @include font-sizes(1.6rem, 2.75rem, 20rem, 87.5rem);
    margin-top: 1rem;
  }

  &__h2 {
    position: relative;
    display: inline-block;
    padding: 0 1.8rem;
    margin: 0 auto;
    @include font-sizes(1.125rem, 1.75rem, 20rem, 87.5rem);

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 1.5rem;
      height: 1px;
      border: 1px solid rgba(#212529, 0.8);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__h3 {
    @include font-sizes(1rem, 1.5rem, 20rem, 87.5rem);
  }

  &__img {
    position: relative;
    flex-grow: 1;
    flex-basis: 320px;
    align-self: flex-end;
    text-align: center;
  }

  &__badge {
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto 1.25rem;
    padding: 0.25rem;
    width: 300px;
    background: #fff;
    border-radius: 0.25rem;
    font-size: .75rem;
  }
}

// クライアント #Clients ---------------------------
#Clients {
  padding: 1.75rem 0;
}

.clients {
  &__wrap {
    overflow: hidden;
    display: flex;
    height: 114px;
    padding-top: 1.75rem;
    align-items: center;
  }

  &__ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__li {
    width: 200px;
    padding: 2.25rem;
  }

  &__wrap {
    &--top .clients__ul {
      -webkit-animation: loop-slide 45s infinite linear 1s both;
      animation: loop-slide 45s infinite linear 1s both;
    }

    &--bottom .clients__ul {
      -webkit-animation: loop-slide 45s infinite linear reverse 1s both;
      animation: loop-slide 45s infinite linear reverse 1s both;
    }

  }
}

@-webkit-keyframes loop-slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes loop-slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

// コンセプト #Concept ---------------------------
.concept {
  &__bg::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../images/lego_bg-l@2x.jpg);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
  }

  &__h2 {
    @include font-sizes(2.2rem, 4.375rem, 20rem, 87.5rem);

    @include media-breakpoint-up(sm) {
      padding-left: 6rem
    }
  }

  &__h3 {
    @include font-sizes(1.375rem, 1.75rem, 20rem, 87.5rem);
    margin-bottom: 1.25rem;
    font-weight: 700;
    line-height: 2;

    span.bg-white {
      padding: 0.25rem 0.5rem;
    }

    @include media-breakpoint-up(sm) {
      padding-left: 6rem
    }
  }
}

// 問題 #Problem ---------------------------
.problem {
  &__h2 {
    @include font-sizes(2rem, 5.5rem, 20rem, 87.5rem);
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }

  &__h3 {
    @include font-sizes(1.375rem, 1.6rem, 20rem, 87.5rem);
    margin: 0;
    text-align: center;
    letter-spacing: -1px;
  }
}

.bg-daidai {
  &-1.lazyloaded {
    min-height: 223px;
    @include daidai(0.87, url($imgDir + "problem_1@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include daidai(0.87, url($imgDir + "problem_1-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-2.lazyloaded {
    min-height: 223px;
    @include daidai(0.87, url($imgDir + "problem_2@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include daidai(0.87, url($imgDir + "problem_2-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-3.lazyloaded {
    min-height: 223px;
    @include daidai(0.87, url($imgDir + "problem_3@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include daidai(0.87, url($imgDir + "problem_3-lg@2x.jpg"));
      @include bg-property(center);
    }
  }
}

// 原則 #Principle ---------------------------
.principle {
  &__h2 {
    @include font-sizes(1.75rem, 5.5rem, 20rem, 87.5rem);
    font-weight: 700;
    line-height: 1.2;
  }

  &__h3 {
    @include font-sizes(1.375rem, 1.6rem, 20rem, 87.5rem);
    margin: 0;
    text-align: center;
  }
}

.bg-aka {
  &-1.lazyloaded {
    @include aka(0.87, url($imgDir + "principle_1@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include aka(0.87, url($imgDir + "principle_1-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-2.lazyloaded {
    @include aka(0.87, url($imgDir + "principle_2@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include aka(0.87, url($imgDir + "principle_2-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-3.lazyloaded {
    @include aka(0.87, url($imgDir + "principle_3@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include aka(0.87, url($imgDir + "principle_3-lg@2x.jpg"));
      @include bg-property(center);
    }
  }
}

// 提案 #ParadigmSift ---------------------------
.paradigmsift {
  &__h2 {
    @include font-sizes(2.25rem, 5.5rem, 20rem, 87.5rem);
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }

  &__subhead {
    @include font-sizes(1rem, 2rem, 20rem, 87.5rem);
    font-weight: 700;
    text-align: center;
  }

  &__h3 {
    @include font-sizes(1.375rem, 1.5rem, 20rem, 87.5rem);
    margin: 0;
    text-align: center;
  }
}

.bg-fuji {
  &-1.lazyloaded {
    min-height: 223px;
    @include fuji(0.87, url($imgDir + "paradigmsift_1@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include fuji(0.87, url($imgDir + "paradigmsift_1-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-2.lazyloaded {
    min-height: 223px;
    @include fuji(0.87, url($imgDir + "paradigmsift_2@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include fuji(0.87, url($imgDir + "paradigmsift_2-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-3.lazyloaded {
    min-height: 223px;
    @include fuji(0.87, url($imgDir + "paradigmsift_3@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include fuji(0.87, url($imgDir + "paradigmsift_3-lg@2x.jpg"));
      @include bg-property(center);
    }
  }
}

// 解決法 #Solution ---------------------------
.solution {
  &__h2 {
    @include font-sizes(2rem, 5.5rem, 20rem, 87.5rem);
    font-weight: 700;
    line-height: 1.2;
  }
}

.solution__elmt {
  //モバイル
  display: grid;
  grid-template-columns: 1fr;
  padding-top: $spacer * 4;

  &--h3 {
    @include font-sizes(1.25rem, 1.75rem, 20rem, 87.5rem);
    margin-top: $spacer * 4;
    letter-spacing: -0.2rem;
  }

  &--p {
    font-size: 1.25rem;
    font-weight: 700;
  }

  &--img {
    position: relative;
    display: block;
    margin: 0 auto;
    z-index: 99;
  }

  &--line {
    position: relative;
    top: -82px;
    display: block;
    margin: 0 auto;
    width: 39%;
    border: 2px dashed #c8c8c8;
    transform: rotate(90deg);

    @include media-breakpoint-up(sm) {
      top: -115px;
    }

    @include media-breakpoint-up(md) {
      border: 0;
      transform: rotate(-15deg);

      &::before {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        width: 300px;
        height: 78px;
        margin: 0 auto;
        background: url($imgDir + "curve@md.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    @include media-breakpoint-up(xl) {
      top: -100px;
      width: 78%;
      transform: rotate(-32deg);

      &::before {
        width: 600px;
        height: 156px;
        background: url($imgDir + "curve@xl.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    @include media-breakpoint-up(xxl) {
      transform: rotate(-12deg);
    }
  }
}

.solution__elmt {

  // タブレット
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, [col-start] 1fr);
  }

  &--0 {
    @include media-breakpoint-up(md) {
      grid-column: col-start / span 3;
      grid-row: 1 / 2;
    }
  }

  &--1 {
    @include media-breakpoint-up(md) {
      grid-column: col-start / span 2;
      grid-row: 2 / 3;
    }
  }

  &--2 {
    @include media-breakpoint-up(md) {
      padding-bottom: 4rem;
      grid-column: col-start 2 / span 2;
      grid-row: 3 / 4;
    }
  }

  &--line {
    @include media-breakpoint-up(md) {
      grid-column: col-start / span 3;
      grid-row: 4 / 5;
    }
  }

  &--3 {
    @include media-breakpoint-up(md) {
      grid-column: col-start / span 2;
      grid-row: 4 / 5;
    }
  }

  &--4 {
    @include media-breakpoint-up(md) {
      grid-column: col-start 2 / span 2;
      grid-row: 5 / 6;
    }
  }

  &--5 {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      width: 70.82%;
      grid-column: col-start / span 3;
      grid-row: 6 / 7;
    }
  }

  // デスクトップ
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(12, [col-start] 1fr);
    place-items: center;
  }

  &--0 {
    @include media-breakpoint-up(xl) {
      grid-column: col-start / span 12;
      grid-row: 1 / 2;
    }
  }

  &--1 {
    @include media-breakpoint-up(xl) {
      grid-column: col-start / span 6;
      grid-row: 2 / 3;
    }
  }

  &--2 {
    @include media-breakpoint-up(xl) {
      padding-bottom: 4rem;
      grid-column: col-start 8 / span 5;
      grid-row: 2 / 3;
    }
  }

  &--line {
    @include media-breakpoint-up(xl) {
      grid-column: col-start / span 12;
      grid-row: 3 / 4;
    }
  }

  &--3 {
    @include media-breakpoint-up(xl) {
      grid-column: col-start / span 5;
      grid-row: 4 / 5;
    }
  }

  &--4 {
    @include media-breakpoint-up(xl) {
      grid-column: col-start 7 / span 6;
      grid-row: 4 / 5;
    }
  }

  &--5 {
    @include media-breakpoint-up(xl) {
      margin: 0 auto;
      width: 70.82%;
      grid-column: col-start / span 12;
      grid-row: 5 / 6;
    }
  }
}

// サービス概要 #Service ---------------------------

.service {
  &__img {
    padding: 3rem 1rem 1rem 0;

    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  &__h3 {
    @include font-sizes(1.5rem, 2.75rem, 20rem, 87.5rem);
    padding-top: 2rem;
  }

  &__li {
    @include font-sizes(1.25rem, 1.5rem, 20rem, 87.5rem);
    padding-bottom: 1rem;
  }
}

// 選ばれる理由 #Reason ---------------------------
.reason {
  &__h2 {
    @include font-sizes(2rem, 5.5rem, 20rem, 87.5rem);
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }

  &__h3 {
    @include font-sizes(1.375rem, 1.7rem, 20rem, 87.5rem);
    margin: 0;
    text-align: center;
  }
}

// 導入事例 #Exp ---------------------------
.exp {
  &__h2 {
    @include font-sizes(1.7rem, 5.5rem, 20rem, 87.5rem);
    font-weight: 700;
    line-height: 1.2;
  }

  &__h3 {
    @include font-sizes(1.4rem, 2.75rem, 20rem, 87.5rem);
    margin-top: 4rem;
    text-align: center;
  }

  &__card {
    min-height: 223px;
  }
}

.bg-ki {
  &-1.lazyloaded {
    @include ki(0.67, url($imgDir + "exp_1@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include ki(0.67, url($imgDir + "exp_1-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-2.lazyloaded {
    @include ki(0.67, url($imgDir + "exp_2@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include ki(0.67, url($imgDir + "exp_2-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-3.lazyloaded {
    @include ki(0.67, url($imgDir + "exp_3@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include ki(0.67, url($imgDir + "exp_3-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-4.lazyloaded {
    @include ki(0.67, url($imgDir + "exp_4@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include ki(0.67, url($imgDir + "exp_4-lg@2x.jpg"));
      @include bg-property(center);
    }
  }

  &-5.lazyloaded {
    @include ki(0.67, url($imgDir + "exp_5@2x.jpg"));
    @include bg-property(center);

    @include media-breakpoint-up(xl) {
      @include ki(0.67, url($imgDir + "exp_5-lg@2x.jpg"));
      @include bg-property(center);
    }
  }
}

.exp__card__bg {
  &--1 {
    min-height: 200px;
    background-image: url($imgDir + "exp_6@2x.jpg");
    @include bg-property(top);

    @include media-breakpoint-up(md) {
      background-image: url($imgDir + "exp_6-lg@2x.jpg");
      @include bg-property(top);
    }
  }

  &--2 {
    min-height: 200px;
    background-image: url($imgDir + "exp_7@2x.jpg");
    @include bg-property(center);

    @include media-breakpoint-up(md) {
      background-image: url($imgDir + "exp_7-lg@2x.jpg");
      @include bg-property(center);
    }
  }

  &--3 {
    min-height: 200px;
    background-image: url($imgDir + "exp_8@2x.jpg");
    @include bg-property(top);

    @include media-breakpoint-up(md) {
      background-image: url($imgDir + "exp_8-lg@2x.jpg");
      @include bg-property(top);
    }
  }
}

//  顧客の声 #Testi ---------------------------
.testi {
  &__h2 {
    @include font-sizes(1.7rem, 5.5rem, 20rem, 87.5rem);
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }

  &__card {
    margin: 0 1rem;
  }
}

//  おすすめできる企業 #Qualify ---------------------------
.qualify {
  &__h2 {
    @include font-sizes(1.6rem, 3.9rem, 20rem, 87.5rem);
    margin: 0 auto;
    padding: 2rem 0 0;
    width: 12em;
    font-weight: 700;
    line-height: 1.2;
  }

  &__li {
    padding-bottom: 1rem;
    font-size: 1.5rem;
  }
}

.triangle {
  margin: -1px auto 2rem;
  width: 67%;
  height: 4rem;
  background-color: $white;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

// 料金体系 #Fee / プロフィール #Prof---------------------------
.table {

  &-th,
  &-td {
    min-width: 124px;
    max-width: 410px;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}

// よくある質問 #FAQ ---------------------------
.faq {
  &__a {
    display: flex;
  }
}

// サイドバー #Sidebar ---------------------------
.sidebar {
  padding: 1rem 0.75rem;
}

.form-check-inline {
  margin-bottom: .75rem;
  padding: .2rem;
  border-radius: .25rem;
  font-size: .875rem;
  font-weight: 700;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: .75rem;


  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  &-item {
    @include media-breakpoint-up(sm) {

      &-1,
      &-4,
      &-5,
      &-8,
      &-9,
      &-btn {
        grid-column: 1 / 3;
      }

      &-2,
      &-6 {
        grid-column: 1 / 2;
      }
    }
  }
}

.form-control,
.form-select {
  display: block;
  padding: .5rem;
  width: 100%;
  border: 1px solid rgba($black, .3);
  border-radius: .25rem;
}


// グローバルナビゲーション #Gnav / #Hmenu ---------------------------
#Gnav {
  padding: 0.5rem;
  background: rgba(#fff, 0.3);
  box-shadow: 0 0 12px rgb(0 0 0 / 5%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.clone-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
  transition: 0.8s;
  transform: translateY(100%);
}

.is-show {
  transform: translateY(0);
}

.brand {
  z-index: 1000;
}

// モバイルメニューボタン閉状態
.hmenu {
  position: fixed;
  top: .55rem;
  right: 1rem;
  z-index: 999;
}

.nav-btn {
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 1000;
  cursor: pointer;

  span {
    display: block;
    position: relative;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background-color: #fff;
    transition: all .4s;
  }

  &__top {
    top: 5px;
  }

  &__middle {
    top: 14px;
  }

  &__bottom {
    top: 23px;
  }

  &.fixed-menu span {
    background-color: rgba($secondary, .5);
  }
}

// モバイルメニューボタン開状態
.nav-btn.active {
  .nav-btn__top {
    background-color: #fff;
    transform: translateY(12px) rotate(-30deg);
  }

  .nav-btn__middle {
    opacity: 0;
  }

  .nav-btn__bottom {
    background-color: #fff;
    transform: translateY(-14px) rotate(30deg);
  }
}

// モバイルメニュー閉状態
#Hmenu {
  display: none;
}

#Hmenu .hmenu__ul {
  display: block;
  right: 3rem;
  list-style-type: none;
  text-align: end;
  opacity: 0;
}

// モバイルメニュー開状態
#Hmenu.panelactive {
  position: absolute;
  display: block;
  top: 5rem;
  right: 0;
  width: 100vw;
  height: auto;
  z-index: 1030;
}

#Hmenu.panelactive .hmenu__ul {
  opacity: 1;
}

#Hmenu.panelactive .hmenu__ul li {
  margin-bottom: .5rem;
  -webkit-animation-name: gnaviAnime;
  animation-name: gnaviAnime;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
}

@-webkit-keyframes gnaviAnime {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes gnaviAnime {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

// メニューサークル
.nav-btn-circle {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: $danger;
  transform: scale(0);
  transition: all .8s;
  z-index: 999;

  &.circleactive {
    transform: scale(9.6);
  }
}


// CTA #CTA ---------------------------
.cta {

  &__h3,
  &__h2 {
    text-align: center;
    line-height: 1.8;
  }

  &__h3 {
    margin-top: 1rem;
    @include font-sizes(1.125rem, 2rem, 20rem, 87.5rem);
  }

  &__h2 {
    @include font-sizes(1.25rem, 3rem, 20rem, 87.5rem);
  }
}

// プライバシーマーク
.grecaptcha-badge {
  opacity: 0;
}

// Google Fonts ---------------------------
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v94/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format("woff2");
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v70/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2) format("woff2");
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v69/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmPq_HTTw.woff2) format("woff2");
  font-display: swap;
}

.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
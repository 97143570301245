//-----------------------------------------------------
// 定義
//-----------------------------------------------------
// フォントサイズ ---------------------------
// $minFS : 最小フォントサイズ (rem)
// $maxFS : 最大フォントサイズ (rem)
// $minVP : 最小横幅 (rem)
// $maxVP : 最大横幅 (rem)
@mixin font-sizes($minFS, $maxFS, $minVP, $maxVP) {
    $vwVal: calc(($maxFS - $minFS) / ($maxVP - $minVP));
    $remVal: calc(-1 * $minVP * $vwVal + $minFS);

    font-size: $minFS;
    font-size: clamp($minFS, $remVal + $vwVal * 100vw, $maxFS);
}

// 文字サイズ拡大縮小 ---------------------------
$font-resize-rdu: 72%;
$font-resize-expn: 133%;


//-----------------------------------------------------
// Bootstrap
//-----------------------------------------------------
// フォント・テキスト
$body-color: #331904;
$font-family-sans-serif: 'Roboto', 'Hiragino Sans', 'Meiryo', 'Hiragino Kaku Gothic ProN', sans-serif;
$font-size-base: 1.125rem !default; // 18px
$small-font-size: .777777em;
$line-height-base: 1.8;
$link-decoration: none;
$sub-sup-font-size: 32.6%;
$mark-padding: 0;
$display-font-weight: 700;
$lead-font-weight: 700;

// 見出し
$headings-font-weight: 700;
$headings-line-height: 1.5;
$headings-margin-bottom: 1.25rem;
$h2-font-size: $font-size-base * 2.75;

// 引用
$blockquote-font-size: $font-size-base;

// ボタン
$btn-font-size: 1rem;
$btn-font-weight: 700;
$input-btn-padding-x-lg: 2rem;
$input-btn-padding-x-sm: 1.5rem;

// カード
$card-border-width: 0;
$card-border-radius: 1.25rem;
$card-cap-bg: none;

// 余白
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 4,
    ) !default;

@mixin img-fluid {
    // Part 1: Set a maximum relative to the parent
    max-width: 100%;
    // Part 2: Override the height to auto, otherwise images will be stretched
    // when setting a width and height attribute on the img element.
    height: auto;
}